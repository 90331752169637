.values {
  // display: flex;

  .img-1 {
    width: 560px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    .values-header {
      display: flex;
      justify-content: center;
      column-gap: 2rem;

      @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
      }
      .values-img {
        width: 80%;
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }

    .card {
      display: flex;
      flex-direction: row;
      background-color: #fff;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border: 2px solid #c90c29;
      border-radius: 20px;
      padding: 20px;

      @media (max-width: 480px) {
        padding: 0;
      }

      .content-container {
        padding: 3.2rem;
        transition: transform 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 50px;

        @media (max-width: 991px) {
          padding: 2rem;
        }
        @media (max-width: 768px) {
          padding: 1.5rem;
        }
        @media (max-width: 480px) {
          row-gap: 1.5rem;
        }

        .content {
          display: flex;
          column-gap: 50px;
          align-items: center;

          @media (max-width: 480px) {
            flex-direction: column;
          }

          .item-header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            background: linear-gradient(to right, #960016, #c90c29);
            -webkit-background-clip: text;
            color: transparent;

            @media (max-width: 480px) {
              justify-content: center;
            }

            h1 {
              font-size: 2.5em;
              margin-right: 15px;
            }
          }
          .item-description {
            @media (max-width: 480px) {
              text-align: center;
              line-height: 1.4em;
            }
          }
        }
      }
      .img-1 {
        margin-left: -390px;
      }
      .img-2 {
        margin-right: -340px;
      }
    }
  }
}
