.demo_request_form_cont {
  padding: 20px 30px;

  .custom_input {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 0;
    align-items: flex-start;

    .cs_form_field {
      padding: 12px 20px;
    }
  }
}
