@import "../../sass/default/color_variable";

.banner-strip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(134deg, $accent 0%, $darker_accent 100%);
  padding: 1rem;

  // height: 5rem;
  // @media (max-width: 991px) {
  //   height: 4.5rem;
  // }
  // @media (max-width: 768px) {
  //   height: 4rem;
  // }
  // @media (max-width: 600px) {
  //   height: 3rem;
  // }
  // @media (max-width: 480px) {
  //   height: 2.5rem;
  // }

  .cs_section_heading {
    margin-bottom: unset;

    .cs_section_title {
      color: white;

      font-size: 1.65rem;
      @media (max-width: 991px) {
        font-size: 1.5rem;
      }
      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
      @media (max-width: 700px) {
        font-size: 1.3rem;
      }
      @media (max-width: 650px) {
        font-size: 1.2rem;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
      }
      @media (max-width: 550px) {
        font-size: 0.9rem;
      }
      @media (max-width: 480px) {
        font-size: 0.85rem;
      }
      @media (max-width: 440px) {
        font-size: 0.8rem;
      }
      @media (max-width: 420px) {
        font-size: 0.75rem;
      }
      @media (max-width: 390px) {
        font-size: 0.7rem;
      }
    }
  }
}
