.enquaire-logo{
  img {
    max-width: unset;

    @media(max-width: 600px){
      width: 2.5rem;
    }
    @media(max-width: 480px){
      width: 2rem;
    }
  }
}
