@import "../../../sass/default/color_variable";

.brand-section {
  padding: 100px 0 150px;
  margin-bottom: 2.5rem;
  .brand-section-heading {
    font-size: 18px;
    font-style: italic;
    color: $secondary;
  }
}
