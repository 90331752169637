.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  max-width: 500px;

  .image-wrapper {
    overflow: hidden;
    border-radius: 20px;

    img {
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.1);
      }


    }


    &.no-border-radius{
      border-radius: unset;
    }

    &.no-zoom{
      transform: scale(1);
      img {
        transition: none;

        &:hover {
          transform: scale(1);
        }


      }
    }
  }

  .img-title{
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;

    &.title-up{
      margin-bottom: 30px;
      height: 60px;
    }
  }
}
