/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #000;
$secondary: #c2c4bf;
$ternary: #5c5c5c;
$border: #eaeaea;
$gray: #fafafa;
$accent: #c90c29;
$darker_accent: #63000f;
$background: #f0f0f0;
$success: #24d57a;

:export {
  white: $white;
  black: $black;
  primary: $primary;
  secondary: $secondary;
  ternary: $ternary;
  border: $border;
  gray: $gray;
  accent: $accent;
  darker_accent: $darker_accent;
  background: $background;
}

:root{
  --accent: #{$accent};
  --primary: #{$primary};
}
