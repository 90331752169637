@import "../../sass/default/color_variable";

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;

  @media(max-width: 768px){
    margin: 2rem 0;
  }
  @media(max-width: 600px){
    margin: 1.5rem 0;
  }
  @media(max-width: 480px){
    margin: 1rem;
  }


  &.visible {
    .divider::before {
      transform: scaleX(1);
      transform-origin: right;
    }
    .divider::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  .divider {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &::before,
    &::after {
      content: "";
      flex-grow: 1;
      height: 2px;
      background-color: $accent;
      margin: 0 15px;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 1s ease-out;
    }

    .divider-text {
      font-size: 18px;
      color: #333;
    }
  }
}
