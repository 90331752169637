@import "../../sass/default/color_variable";

.mission-cont {
  position: relative;
  margin: 5%;
  background-color: $white;
  display: flex;
  border-radius: 0 0 0 114px;
  background-color: $background;

  @media (max-width: 1110px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 575px) {
    border-radius: 0 114px 0 0;
  }

  .mission-content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 30rem);

    @media (max-width: 1199px) {
      width: calc(100% - 29rem);
    }
    @media (max-width: 1110px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      padding: 30px;
    }

    .cs_section_heading {
      display: flex;
      align-items: center;
      column-gap: 10px;
      .card-title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 10px;
        color: #333;
      }
    }

    .card-description {
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 2.25rem;
      margin-bottom: 0;

      @media (max-width: 1600px) {
        line-height: 2rem;
      }
      @media (max-width: 1420px) {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }
      @media (max-width: 1265px) {
        font-size: 1rem;
        line-height: 1.6rem;
      }
      @media (max-width: 1199px) {
        line-height: 1.5rem;
      }
      @media (max-width: 1110px) {
        font-size: 1.2rem;
        line-height: 2rem;
      }
      @media (max-width: 1000px) {
        line-height: 1.8rem;
      }
      @media (max-width: 880px) {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
        line-height: 1.4rem;
      }
      @media (max-width: 350px) {
        font-size: 0.9rem;
        line-height: 1.2rem;
      }

    }
  }

  .mission-img {
    position: absolute;
    bottom: 0;
    right: -1.5rem;
    align-self: flex-end;

    @media (max-width: 1110px) {
      position: relative;
      align-self: center;
      right: unset;
    }

    img {
      max-width: 36rem;

      @media (max-width: 800px) {
        width: 32rem;
      }
      @media (max-width: 650px) {
        width: 28rem;
      }
      @media (max-width: 480px) {
        width: 25rem;
      }
      @media (max-width: 430px) {
        width: 23rem;
      }
      @media (max-width: 400px) {
        width: 18rem;
      }
    }
  }
}
