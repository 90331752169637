.products {
  .checkin-assistants-cont {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    column-gap: 30px;
    justify-content: center;
    .conversation-clip {
      height: auto;
      width: 1000px;
      background-color: $secondary;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nurses-img-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;

      img {
        width: 100%;
      }
    }

    .smooth-journey {
      width: 63%;
    }
  }
}
