@import "../../sass/default/color_variable";

.demo-page {
  .demo-page-container {
    padding: 5rem;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    background-color: $white;
    border-radius: 30px;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7rem;
    border: 1px solid $accent;

    .demo-page-heading {
      display: flex;
      flex-wrap: wrap;
    }

    @media (max-width: 991px) {
      margin: 2rem 0;
      padding: 2rem 0.5rem;
    }

    .cs_section_heading {
      @media (max-width: 991px) {
        margin-left: 30px;
      }


    }

    .cs_btn {

      margin-top: 2rem;

    }
  }
}
