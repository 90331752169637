@import "../../sass/default/color_variable";

.slider-container{
  [id^="slider_"] {
    column-gap: 2rem;
    padding: 1rem;
  }

  .brand_logo {
  padding: 0.5rem 2rem;
  justify-content: center;
  max-height: 150px;
  object-fit: contain;
  width: auto;
  height: auto;
  background-color: $border;
  border-radius: 0.5rem;
  opacity: 0.9;

  &.width_220{
    width: 220px !important;
  }
  &.width_230{
    width: 230px !important;
  }
  &.width_250{
    width: 250px !important;
  }
  &.width_300{
    width: 300px !important;
  }
  &.width_320{
    width: 320px !important;
  }
}}
