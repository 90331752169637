.contact-page{
  .hero-img{
    align-items: center;

    .cs_banner_text,.cs_banner_title {
      padding-left: 0;
      margin: 0;
    }
  }
}
