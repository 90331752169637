@import "../../sass/default/color_variable";

.home-section-1 {
  .container {
    .home-section-1-wrapper {
      display: grid;
      grid-template-columns: 1fr 1.25fr;

      @media(max-width: 768px){
        grid-template-columns: 1fr;
      }
      align-items: center;
      column-gap: 5%;
      .home-section-1-content{
        padding: 10% 0;

        @media(max-width: 768px){
          padding: 0;
        }
      }
      .video-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 30px;
        height: 100%;

        @media(max-width: 768px){
          max-height: 300px;
        }

        .enquaire-logo{
          z-index:1;
        }

        .cs_hero_btn_wrap{
          z-index: 1;
        }

        .enquaire-logo{
          width: 40%;
        }
        .video-background{
          border-radius: 30px;
          max-height: 25rem;

          @media(max-width: 768px){
            max-height: 300px;
          }
        }
      }

      .cs_section_heading {
        align-items: flex-start;
      }
    }
  }
}
