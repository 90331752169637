.benefit-section {
  .benefits-subtitle {
    width: 50%;
    margin: -10px auto 0 !important;
  }

  .benefits-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    row-gap: 200px;
  }
}
