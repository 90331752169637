@import "../../../sass/default/color_variable";

.cs_banner_text {
  width: 32%;
  margin-bottom: 1.9rem;

  @media (max-width: 1199px) {
    width: 43%;
    margin-bottom: 0.5rem;
  }

  .cs_banner_title {
    font-weight: 500;
    padding: 20px;
    color: $black;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    @media (max-width: 1700px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1199px) {
      font-size: 3.2rem;
    }
    @media (max-width: 991px) {
      font-size: 2.5rem;
    }
    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 0rem;
      padding: 0rem;
    }
    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
    @media (max-width: 480px) {
      font-size: 1.45rem;
    }
    @media (max-width: 430px) {
      font-size: 1.3rem;
    }
    @media (max-width: 380px) {
      font-size: 1.1rem;
    }
    @media (max-width: 350px) {
      font-size: 1rem;
    }
  }
}
