@import "../sass/default/color_variable";

.mute-icon,
.volume-icon {
  width: 4rem;
  height: 4rem;
  @media (max-width: 1700px) {
    width: 3.75rem;
    height: 3.75rem;
  }
  @media (max-width: 1199px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media (max-width: 991px) {
    width: 3.25rem;
    height: 3.25rem;
  }
  @media (max-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
  @media (max-width: 600px) {
    width: 2.5rem;
    height: 2.5rem;
  }
  @media (max-width: 480px) {
    width: 2rem;
    height: 2rem;
  }
}
