.home-hero-video-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .home-hero-video {
    width: 100%;
    z-index: -2;

    &::-webkit-media-controls {
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
    &::-webkit-media-controls-play-button {
      display: none !important;
    }
    &::-webkit-media-controls-start-playback-button {
      display: none !important;
    }
  }

  #home-hero-video::-webkit-media-controls {
    display: none !important;
  }
  #home-hero-video::-webkit-media-controls-enclosure {
    display: none !important;
  }
  #home-hero-video::-webkit-media-controls-play-button {
    display: none !important;
  }
  #home-hero-video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  .mute-button {
    position: absolute;
    background: transparent;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.5;

    bottom: 2rem;
    right: 1.5rem;

    z-index: 1;

    @media (max-width: 1199px) {
      bottom: 1.5rem;
      right: 1rem;
    }
    @media (max-width: 991px) {
      bottom: 1rem;
      right: 0.6rem;
    }
    @media (max-width: 768px) {
      bottom: 0.8rem;
      right: 0.4rem;
    }
    @media (max-width: 600px) {
      bottom: 0.5rem;
      right: 0.25rem;
    }
    @media (max-width: 480px) {
      bottom: 0.5rem;
      right: 0.2rem;
    }

    svg {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }
}
