.cs_section_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 30px;

  .bullet{
    height: 2rem;
    align-self: center;

    @media(max-width: 600px){
      height: 1.3rem;
    }
    @media(max-width: 480px){
      height: 1rem;
    }
  }

  .cs_section_title{
    align-self: center;
  }

  &.center-align{
    justify-content: center;
  }
}
