@import "../../sass/default/color_variable";

.email-sent-message{
  background-color: $success;
  color: $white;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
}
