.open-modal-btn{
  margin-top: 2.6rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1.2rem !important;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.7rem 1rem !important;
  }
  @media (max-width: 420px) {
    font-size: 0.7rem;
    padding: 0.6rem 1rem !important;
  }
  @media (max-width: 370px) {
    font-size: 0.6rem;
    margin-top: 0.4rem !important;
    padding: 0.3rem 1rem !important;
  }
}

.early-access-form-modal {
  margin: 5rem 0;
  padding-bottom: 10rem;

  .modal_header {
    display: flex;
    justify-content: flex-end;
    padding: 30px 50px 0;

    .close {
      background-color: transparent;
      border: none;
      font-size: 32px;
    }
  }
}
