@import "../../../sass/default/color_variable";

.features-section {
  background-color: $background;

  .features {
    display: grid;
    column-gap: 30px;
    justify-content: center;
    justify-items: center;
    margin: 50px 0;

    @media(max-width: 768px){
      grid-template-columns: 1fr !important;
    }
  }
}
