.early-access-form{
  padding: 2rem;
  .custom_input{
    margin-bottom: 1.5rem;
  }
  .terms-checkbox{
    margin-right: 0.6rem;
  }

  .form-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    column-gap: 1rem;
    flex-wrap: wrap-reverse;
    row-gap: 1rem;
  }
}
