@import "../../sass/default/color_variable";

.team-member-card {
  position: relative;
  width: min-content;
  height: 100%;

  @media (min-width: 890px) {

  }

  .cs_member_designation {
    font-size: 1rem;
  }

  .card-image {
    transition: opacity 0.5s ease;

    @media (max-width: 480px) {
      width: 20rem !important;
    }
    @media (max-width: 350px) {
      width: 18rem !important;
    }
  }

  &.hover-effect {
    &:hover {
      transform: scale(1.05);

      .card-image {
        opacity: 0;
      }

      .cs_member_description {
        opacity: 1;
      }
    }

    .cs_member_description {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.95);
      opacity: 0;
      transition: opacity 0.5s ease;
      font-size: 1.2rem;
      color: $ternary;
      padding: 0 5%;
      text-align: left;
    }
  }

  .card-size-sm {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .cs_member_description {
    font-size: 1rem;
    color: $ternary;
  }
}
